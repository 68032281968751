import React from "react";
import Link from "../../components/Navigation/Link";
import useTitleContext from "../../context/useTitleContext";
import "./Impressum.scss";
import BackBtn from "../../components/BackBtn/BackBtn";

function Impressum({ location }) {
  let newTitle = "Impressum";
  const notImpressum = location.pathname === "/kontakt";
  notImpressum && (newTitle = "Kontakt");
  useTitleContext(newTitle);

  const email = "haas@fingerweb.org";
  return (
    <>
      <BackBtn></BackBtn>
      <div className="impressum container">
        <div className="impressum__anschriftContainer">
          <h2>Anschrift:</h2>
          <span className="impressum__span">Florian Haas</span>
          <span className="impressum__span">Hartmann Ibach Strasse 85</span>
          <span className="impressum__span">60389 Frankfurt a.M.</span>
        </div>
        <div className="impressum__emailContainer">
          <h2>Email:</h2>
          <span className="impressum__span">{email}</span>
          <button className="impressum__button">
            <a href={"mailto:" + email}>Kontaktieren</a>
          </button>
        </div>
        <div className="impressum__datenschutz">
          <Link to="/datenschutz/">Datenschutz</Link>
        </div>
      </div>
    </>
  );
}

export default Impressum;
