import React from "react";
import { navigate } from "gatsby";
import "./backBtn.scss";
import arrow from "../../images/arrowBlack.svg";

function BackBtn() {
  return (
    <button onClick={() => navigate(-1)} className="backBtn">
      <img src={arrow} alt="&#10229;" className="backBtn__arrow" />
    </button>
  );
}

export default BackBtn;
